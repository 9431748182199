import React, { useState, useEffect } from 'react';
import Modal from 'components/Core/Modal';
import Button from 'components/Core/Button';
import ToolboxSplashFiltersList from '../List/ToolboxSplashFiltersList';
import closeIcon from 'images/close-icon.png';

import { Container } from './styles';
import './modal-responsive.less';

const ToolboxSplashFiltersMobile = ({
  view,
  filters,
  handleListItemClick,
  hasSelectedFilters,
  clearFilters,
  isSelected,
  handleFilterButtonClick,
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const container = document.querySelector('#___gatsby');
    if (view) {
      const scrollPos = window.pageYOffset;
      container.style.top = `-${scrollPos}px`;
      container.style.position = 'fixed';
      container.style.width = '100%';
      setScrollPosition(scrollPos);
      setTimeout(() => setShowButton(true), 500);
    } else {
      setShowButton(false);
    }
    return () => {
      container.removeAttribute('style');
    };
  }, [view]);

  const handleClose = (isClose = false) => {
    const container = document.querySelector('#___gatsby');
    container?.removeAttribute('style');
    document.body.style.overflow = 'auto';
    window.scrollTo(0, scrollPosition);
    handleFilterButtonClick(isClose);
  };

  return (
    <Modal className="toolbox-details-filters-mobile--modal" open={view} hideCloseIcon>
      <Container>
        <div className="toolbox-details-filters-mobile--container">
          <h6
            className="toolbox-details-filters-mobile--clear-filters"
            onClick={() => handleClose(true)}
          >
            <img src={closeIcon} className="toolbox-details-filters-mobile--close-icon" />
          </h6>
          <p
            className="toolbox-details-filters-mobile--clear-filters"
            onClick={() => clearFilters()}
          >
            Clear all
          </p>
        </div>
        <ToolboxSplashFiltersList
          filters={filters}
          handleListItemClick={handleListItemClick}
          isSelected={isSelected}
        />
        <div
          className={`toolbox-details-filters-mobile--apply-filter ${
            showButton ? 'show-button' : ''
          }`}
        >
          <Button
            type="primary"
            width="calc(100% - 90px)"
            shadow={false}
            onClick={() => handleClose(false)}
          >
            Apply Filter
          </Button>
        </div>
      </Container>
    </Modal>
  );
};

export default ToolboxSplashFiltersMobile;
