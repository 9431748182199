import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--grey-1);
  position: relative;
  padding: 40px 45px 25px 45px;
  .clear-filters {
    z-index: 1;
    display: block;
    color: var(--titan-blue-3);
    position: absolute;
    top: 20px;
    right: 30px;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }
  @media (max-width: 991px) {
    padding: 55px 40px 25px 40px;
  }
  @media (max-width: 575px) {
    display: none;
  }
`;

export { Container };
