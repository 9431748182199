import styled from 'styled-components';

const Container = styled.div`
  .row-container {
    padding: 0;
  }
  .toolbox-details-filters-list--title {
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--grey-3);
    color: var(--darkest);
  }
  .toolbox-details-filters-list--list-item {
    padding-bottom: 15px;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }
  .selected {
    color: var(--titan-blue-3);
  }
  .toolbox-details-filters-list--remove-filter-icon {
    width: 18px;
    height: 18px;
    margin-top: -3px;
    margin-left: 7px;
    vertical-align: middle;
    display: inline-block;
  }

  @media (max-width: 575px) {
    overflow: auto;
    height: 100%;

    .toolbox-details-filters-list--title {
      padding-bottom: 10px;
    }
  }
`;

export { Container };
