import React from 'react';
import ToolboxSplashFiltersList from '../List/ToolboxSplashFiltersList';

import { Container } from './styles';

const ToolboxSplashFiltersDesktop = ({
  filters,
  handleListItemClick,
  clearFilters,
  isSelected,
}) => (
  <Container>
    <p className="clear-filters" onClick={() => clearFilters()}>
      Clear All
    </p>
    <ToolboxSplashFiltersList
      filters={filters}
      handleListItemClick={handleListItemClick}
      isSelected={isSelected}
    />
  </Container>
);

export default ToolboxSplashFiltersDesktop;
