import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
  .toolbox-detail-posts-no-results--text {
    margin-top: 20px;
    margin-bottom: 0;
  }
  & span {
    color: var(--primary-blue);
    &:hover {
      cursor: pointer;
    }
  }
`;

export { Container };
