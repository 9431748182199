import styled from 'styled-components';

const Container = styled.div`
  padding: 0 28px;
  .toolbox-detail-posts--title-container {
    display: flex;
    justify-content: space-between;
  }
  .toolbox-detail-posts--post-count-desktop {
    display: block;
    @media (max-width: 575px) {
      display: none;
    }
  }
  .toolbox-detail-posts--filter-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
  }
  .toolbox-detail-posts--filter-btn {
    margin-bottom: 15px;
    display: flex;
  }
  .selected {
    color: var(--titan-blue-3);
  }
  .toolbox-detail-posts--btn {
    margin: 60px auto;
    display: flex;
  }
  @media (max-width: 991px) {
    padding: 0;
    .toolbox-detail-posts--title-container {
      margin-top: -30px;
    }
  }
`;

const MobileFilterHeader = styled.div`
  display: none;
  justify-content: space-between;
  @media (max-width: 575px) {
    display: flex;
  }
`;

export { Container, MobileFilterHeader };
