import styled from 'styled-components';

const Container = styled.div`
  height: 100%;
  .signup-form--title {
    font-family: var(--font-family-heading);
    font-size: var(--font-size-22);
  }
  .webinar-on-demand--placeholder-title {
    text-align: center;
    padding: 0 20px;

    @media (max-width: 575px) {
      font-size: var(--font-size-22);
    }
  }

  .card--body-wrapper {
    padding-bottom: 50px !important;
  }

  .webinar-splash-card-footer {
    position: absolute;
    bottom: 20px;
    right: 32px;
    display: flex;
    align-items: center;
  }

  .webinar-splash-card-footer-recap-cta {
    color: var(--titan-blue-3);
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-700);
    transition: all 0.8s;

    &:hover {
      color: var(--titan-blue-4);
    }
  }

  .webinar-splash-card-footer-watch-cta {
    background: var(--titan-blue-3);
    border-radius: 1px;
    color: white;
    padding: 9px 20px;
    margin-left: 25px;
    transition: all 0.8s;
    line-height: 150%;

    &:hover {
      background: var(--titan-blue-4);
    }
  }

  @media (min-width: 576px) and (max-width: 1060px) {
    .card--body-wrapper {
      padding-bottom: 80px !important;
    }

    .webinar-splash-card-footer {
      flex-direction: column-reverse;
      left: 0;
      right: auto;
    }

    .webinar-splash-card-footer-watch-cta {
      margin-left: 30px;
    }

    .webinar-splash-card-footer-recap-cta {
      margin-top: 10px;
    }
  }

  @media (max-width: 575px) {
    .card--body-wrapper {
      padding-bottom: 70px !important;
    }
  }
`;

export { Container };
