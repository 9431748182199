import styled from 'styled-components';
import { Row } from 'components/Core/Grid';

const LeftContent = styled.div`
  .toolbox-detail-hero--headline {
    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }
  .toolbox-detail-hero--title {
    margin: 15px 0 10px 0;
    @media (max-width: 991px) {
      margin-top: 20px;
    }
  }

  h4,
  p {
    margin: 0;
  }
`;

const AuthorImage = styled.div`
  height: 84px;
  width: 84px;
  border-radius: 50%;
  top: 0;
  left: 0;
  background-position: center;
  background-size: contain;
  position: absolute;
  @media (max-width: 575px) {
    width: 50px;
    height: 50px;
    top: -1px;
    left: -1px;
  }
`;

const AuthorImageCircle = styled.div`
  height: 90px;
  width: 90px;
  border-radius: 50%;
  bottom: -23px;
  right: -35px;
  background-position: center;
  background-size: contain;
  background-color: var(--white);
  border: 3px solid var(--white);
  position: absolute;

  @media (max-width: 991px) {
    right: -20px;
  }

  @media (max-width: 575px) {
    width: 55px;
    height: 55px;
    bottom: -20px;
    right: -14px;
  }
`;

const Container = styled(Row)`
  padding: 0 28px;
  display: flex;
  align-items: center;
  @media (max-width: 900px) {
    flex-direction: column-reverse;
  }
`;

const FeaturedImage = styled.div`
  position: relative;
  height: 425px;
  width: 100%;

  h2 {
    margin: 0;
  }

  .image-wrapper {
    transition: height 0.9s;
    overflow: hidden;
  }

  .before-enter {
    opacity: 0;
  }

  .entering {
    opacity: 1;
    transition: opacity 1.3s;
  }

  .before-leave {
    opacity: 1;
  }

  .leaving {
    opacity: 0;
    transition: opacity 0.9s;
  }

  @media (max-width: 575px) {
    height: 240px;
  }

  .toolbox-splash-hero--placeholder-content {
    display: flex;
    height: 100%;
    justify-content: center;
    padding: 0 90px;

    .toolbox-splash-hero--placeholder-content-title {
      color: white;
      text-align: center;
      align-self: center;

      @media (max-width: 575px) {
        font-size: var(--font-size-28);
      }
    }

    @media (max-width: 575px) {
      padding: 0 30px;
    }
  }
`;

const CountdownContainer = styled.div`
  display: flex;
  justify-content: center;
  .toolbbox-detail-hero--live-now {
    color: #5bc236;
  }
`;

export {
  AuthorImage,
  AuthorImageCircle,
  Container,
  CountdownContainer,
  FeaturedImage,
  LeftContent,
};
