import React, { useState, useEffect, useMemo } from 'react';
import { Col } from 'components/Core/Grid';
import { getSubheading } from 'utils/text';
import { splashCheck } from 'utils/urlHelper';
import useCountdown from 'utils/useCountdown';
import TinyCrossfade from 'react-tiny-crossfade';
import Button from 'components/Core/Button';
import Countdown from 'components/Countdown';
import BackgroundImage from 'components/Core/BackgroundImage';
import { getPostDate, isLive, isUpcomingPost } from 'components/Page/Webinar/utils/postsUtils';
import Bookmark from 'components/Page/Toolbox/Bookmark';
import useBreakpointView from 'utils/useBreakpointView';

import {
  AuthorImage,
  AuthorImageCircle,
  Container,
  CountdownContainer,
  FeaturedImage,
  LeftContent,
} from './styles';

const ToolboxSplashHero = ({ featuredPost, placeholderImage }) => {
  const {
    featuredImage,
    webinarTitle,
    excerpt,
    mainContent,
    webinarDateNoYearNoTime,
    webinarTime,
    seo,
    speakers,
  } = featuredPost;
  const pageUrl = seo?.pageUrl;
  const isTablet = useBreakpointView(['xs', 'sm', 'md']);
  const [postStatus, setPostStatus] = useState('upcoming');
  const [authorImage, setAuthorImage] = useState(speakers ? speakers[0] : null);
  const countdown = useCountdown(getPostDate(featuredPost));

  useEffect(() => {
    if (isLive(featuredPost)) {
      setPostStatus('live');
    } else if (isUpcomingPost(featuredPost)) {
      setPostStatus('upcoming');
    } else {
      setPostStatus('onDemand');
    }
  }, []);

  useEffect(() => {
    let authorImageIndex = 0;

    if (speakers?.length > 1) {
      const timer = setInterval(() => {
        if (authorImageIndex === speakers.length - 1) {
          authorImageIndex = 0;
        } else {
          authorImageIndex++;
        }

        setAuthorImage(speakers[authorImageIndex]);
      }, 2500);

      return () => clearInterval(timer);
    }
  }, []);

  const getContent = () => {
    switch (postStatus) {
      case 'live':
        return {
          button: 'Join the Webinar',
          status: <p className="font-medium toolbbox-detail-hero--live-now">Live now</p>,
        };
      case 'onDemand':
        return {
          button: 'Watch Now',
          status: null,
        };
      case 'upcoming':
        return {
          button: 'Register Now',
          status: <Countdown time={countdown} />,
        };
      default:
        return {
          button: 'Watch Now',
          status: null,
        };
    }
  };

  const BookmarkWrapper = ({ children }) =>
    !['live', 'upcoming'].includes(postStatus) ? (
      <Bookmark data={featuredPost} url={splashCheck(pageUrl)} type="webinar" right="10">
        {children}
      </Bookmark>
    ) : (
      children
    );

  const featuredPostCol = useMemo(
    () => (
      <Col xs={24} lg={16}>
        <BookmarkWrapper>
          <FeaturedImage featuredImage={featuredImage?.image?.file?.url || placeholderImage?.src}>
            <BackgroundImage image={featuredImage || placeholderImage} />
            {!featuredImage && (
              <div className="toolbox-splash-hero--placeholder-content">
                <h2 className="toolbox-splash-hero--placeholder-content-title">{webinarTitle}</h2>
              </div>
            )}
            {authorImage && (
              <AuthorImageCircle>
                <TinyCrossfade className="image-wrapper">
                  <AuthorImage
                    style={{ backgroundImage: `url(${authorImage?.photo?.image?.file?.url})` }}
                    key={authorImage?.photo?.image?.file?.url}
                  />
                </TinyCrossfade>
              </AuthorImageCircle>
            )}
          </FeaturedImage>
        </BookmarkWrapper>
      </Col>
    ),
    [postStatus],
  );

  return (
    <Container gutter={56} direction={isTablet ? 'column-reverse' : 'row'}>
      <Col xs={24} lg={8}>
        <LeftContent>
          <p className="toolbox-detail-hero--headline">
            {webinarDateNoYearNoTime} @ {webinarTime} PT
          </p>
          <h4 className="toolbox-detail-hero--title">{webinarTitle}</h4>
          <p className="font-medium">{getSubheading(excerpt?.excerpt, mainContent, 100)}</p>
          <Button margin="25px 0 20px 0" width="100%" shadow={false} to={splashCheck(pageUrl)}>
            {getContent().button}
          </Button>
          <CountdownContainer>{getContent().status}</CountdownContainer>
        </LeftContent>
      </Col>
      {featuredPostCol}
    </Container>
  );
};

export default ToolboxSplashHero;
