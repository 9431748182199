import React, { useState, useEffect } from 'react';
import { Col, Row } from 'components/Core/Grid';
import Button from 'components/Core/Button';
import filterIcon from 'images/filter-icon.svg';
import filterIconBlue from 'images/filter-icon-blue.svg';
import { getOnDemandPosts } from 'components/Page/Webinar/utils/postsUtils';
import ToolboxSplashPosts from './Posts/ToolboxSplashPosts';
import ToolboxSplashFiltersContainer from './Filters/ToolboxSplashFiltersContainer';
import ToolboxSplashPostsNoResults from './ToolboxSplashPostsNoResults/ToolboxSplashPostsNoResults';

import { Container, MobileFilterHeader } from './styles';

const ToolboxSplashPostsContainer = ({ form, posts, placeholderImage, noResultsImage }) => {
  const onDemandPosts = getOnDemandPosts(posts);
  const [displayedPosts, setDisplayedPosts] = useState(onDemandPosts);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [selectedMobileFilters, setSelectedMobileFilters] = useState([]);
  const [displayMobileFilters, toggleMobileFilters] = useState(false);
  const [totalPosts, setTotalPosts] = useState(onDemandPosts?.length);
  const [numberOfPosts, setNumberOfPosts] = useState(6);

  useEffect(() => {
    let filteredPosts = onDemandPosts.filter((post) => {
      return (
        post.seo?.taxonomy?.industries?.some(
          (category) => selectedFilters.indexOf(category) !== -1,
        ) ||
        post.seo?.taxonomy?.topics?.some((category) => selectedFilters.indexOf(category) !== -1)
      );
    });

    filteredPosts = selectedFilters.length ? filteredPosts : onDemandPosts;
    setTotalPosts(filteredPosts.length);
    setDisplayedPosts(filteredPosts.slice(0, numberOfPosts));
  }, [selectedFilters, displayMobileFilters, numberOfPosts]);

  useEffect(() => {
    if (displayMobileFilters) {
      setSelectedMobileFilters([...selectedFilters]);
    }
  }, [displayMobileFilters]);

  const handleListItemClick = (listItem) => {
    let filteredList;
    const selFilters = displayMobileFilters ? selectedMobileFilters : selectedFilters;

    filteredList = selFilters.includes(listItem)
      ? selFilters.filter((filter) => filter !== listItem)
      : [...selFilters, listItem];

    filters.forEach((filter) => {
      if (filteredList.some((listItem) => filter.list.indexOf(listItem) !== -1)) {
        if (!filteredList.includes(`All ${filter.category}`)) {
          filteredList = [...filteredList, `All ${filter.category}`];
        }
      } else {
        filteredList = filteredList.filter(
          (filteredItem) => filteredItem !== `All ${filter.category}`,
        );
      }
    });

    if (displayMobileFilters) {
      setSelectedMobileFilters(filteredList);
    } else {
      setSelectedFilters(filteredList);
    }
  };

  const isSelected = (listItem) =>
    (displayMobileFilters ? selectedMobileFilters : selectedFilters).includes(listItem);

  const clearFilters = () => {
    setDisplayedPosts(onDemandPosts);
    if (displayMobileFilters) {
      setSelectedMobileFilters([]);
    } else {
      setSelectedFilters([]);
    }
  };

  const handleFilterButtonClick = (isMobileClose) => {
    if (displayMobileFilters && !isMobileClose) {
      setSelectedFilters([...selectedMobileFilters]);
    }
    toggleMobileFilters(!displayMobileFilters);
  };

  const onShowMorePostsClick = () => {
    setNumberOfPosts(numberOfPosts + 6);
  };

  const filters = [
    {
      category: 'Industries',
      title: 'Filter by Industry',
      list: ['HVAC', 'Plumbing', 'Electrical', 'Garage Door', 'Water Treatment', 'Chimney Sweep'],
    },
    {
      category: 'Topics',
      title: 'Filter by Topic',
      list: ['Marketing', 'Productivity', 'Management', 'Operations'],
    },
  ];

  return (
    <Container>
      <div className="toolbox-detail-posts--title-container">
        <h6 style={{ marginBottom: 30 }}>On-Demand Webinars</h6>
        <p className="toolbox-detail-posts--post-count-desktop">{`Showing 1-${displayedPosts.length} of ${totalPosts}`}</p>
      </div>
      <Row gutter={40} type="flex">
        <Col xs={24} sm={8}>
          <ToolboxSplashFiltersContainer
            filters={filters}
            isSelected={isSelected}
            handleListItemClick={handleListItemClick}
            clearFilters={clearFilters}
            handleFilterButtonClick={handleFilterButtonClick}
            hasSelectedFilters={
              (displayMobileFilters ? selectedMobileFilters : selectedFilters).length
            }
            displayMobileFilters={displayMobileFilters}
          />
        </Col>
        <Col xs={24} sm={16}>
          <MobileFilterHeader id="toolbox-detail-posts--mobile-posts">
            <p
              onClick={() => handleFilterButtonClick()}
              className={`toolbox-detail-posts--filter-btn ${selectedFilters.length && 'selected'}`}
            >
              {selectedFilters.length ? (
                <img src={filterIconBlue} className={`toolbox-detail-posts--filter-icon`} />
              ) : (
                <img src={filterIcon} className={`toolbox-detail-posts--filter-icon`} />
              )}
              Filter
            </p>
            <p>{`Showing 1-${displayedPosts.length} of ${totalPosts}`}</p>
          </MobileFilterHeader>
          <Row gutter={[40, 40]} type="flex">
            {displayedPosts.length ? (
              displayedPosts.map((post, index) => (
                <ToolboxSplashPosts key={index} post={post} placeholderImage={placeholderImage} />
              ))
            ) : (
              <ToolboxSplashPostsNoResults
                noResultsImage={noResultsImage}
                clearFilters={clearFilters}
              />
            )}
          </Row>
          {totalPosts > 6 && totalPosts > displayedPosts.length && (
            <Button
              className="toolbox-detail-posts--btn"
              type="tertiary"
              size="big"
              onClick={() => onShowMorePostsClick()}
            >
              See more posts
            </Button>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ToolboxSplashPostsContainer;
