import React from 'react';

import { Container } from './styles';

const Countdown = ({ time }) => {
  const addLeadingZeros = (value) => {
    value = String(value);
    while (value.length < 2) {
      value = `0${value}`;
    }
    return value;
  };

  const timeData = [
    {
      unit: 'Day',
      key: 'days',
    },
    {
      unit: 'Hour',
      key: 'hours',
    },
    {
      unit: 'Min',
      key: 'min',
    },
  ];

  return (
    <Container>
      {time &&
        timeData.map(
          (obj, index) =>
            time[obj.key] !== 0 && (
              <p style={{ marginRight: 10, display: 'inline' }} className="font-medium" key={index}>
                {addLeadingZeros(time[obj.key])} {time[obj.key] === 1 ? obj.unit : `${obj.unit}s`}
              </p>
            ),
        )}
    </Container>
  );
};

export default Countdown;
