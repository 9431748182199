import React from 'react';
import { Col } from 'components/Core/Grid';
import HyperLink from 'components/Core/HyperLink';
import { getSubheading } from 'utils/text';
import { splashCheck } from 'utils/urlHelper';
import Cards from 'components/Core/Cards';
import Button from 'components/Core/Button';
import Bookmark from 'components/Page/Toolbox/Bookmark';
import { Container } from './styles';

const ToolboxSplashPosts = ({ post, placeholderImage }) => {
  const ctaLink = splashCheck(post?.onDemandRedirectSlug || post.seo.pageUrl);

  return (
    <Col xs={24} sm={12}>
      <Container>
        <Bookmark type="webinar" url={ctaLink} data={post} title={post.webinarTitle}>
          <Cards
            type="Image"
            bodyType="simple"
            maxImageHeight="216px"
            coverImage={post.featuredImage || placeholderImage}
            category={`aired on ${post.webinarMonthDayYear}`}
            title={post.webinarTitle}
            description={getSubheading(post.excerpt?.excerpt, post.mainContent, 130)}
            cta={
              <div className="webinar-splash-card-footer">
                {post.onDemandRedirectSlug && (
                  <HyperLink
                    href={post.onDemandRedirectSlug}
                    className="webinar-splash-card-footer-recap-cta"
                  >
                    Read Recap
                  </HyperLink>
                )}
                <Button
                  to={splashCheck(post?.seo?.pageUrl)}
                  className="webinar-splash-card-footer-watch-cta font-sm"
                >
                  Watch Now
                </Button>
              </div>
            }
            ctaLink={ctaLink}
            fade
          />
        </Bookmark>
      </Container>
    </Col>
  );
};

export default ToolboxSplashPosts;
