import styled, { css } from 'styled-components';

export const mobileFilterContainer = css`
  display: block;
  position: relative;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100vw;
  min-height: -webkit-fill-available;
  padding: 110px 20px 170px;
  background-color: var(--white);
`;

const Container = styled.div`
  position: fixed !important;

  .title {
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--grey-3);
    color: var(--darkest);
  }
  .list-item {
    padding-bottom: 15px;
    &:hover {
      cursor: pointer;
    }
  }
  .selected {
    color: var(--titan-blue-3);
  }
  .toolbox-details-filters-mobile--container {
    z-index: 1000;
    background: var(--white);
    height: 70px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    left: 0;
    width: 100%;
    padding: 20px 30px;
    box-shadow: 0px 3px 10px rgba(11, 26, 33, 0.08) !important;
  }
  .toolbox-details-filters-mobile--clear-filters {
    display: block;
    margin: 0;

    &:hover {
      cursor: pointer;
    }
  }
  .toolbox-details-filters-mobile--close-icon {
    width: 20px;
    height: 20px;
  }
  .remove-filter-icon {
    width: 18px;
    height: 18px;
    margin-top: -3px;
    margin-left: 7px;
  }
  .toolbox-details-filters-mobile--apply-filter {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    left: 0;
    background: white;
    justify-content: center;
    padding: 10px 0;
    border-top: 1px solid var(--grey-3) !important;

    &.show-button {
      position: fixed;
    }
  }
  @media (min-width: 576px) {
    display: none;
  }
  @media (max-width: 575px) {
    ${mobileFilterContainer}
    .title {
      padding-bottom: 10px;
    }
  }
`;

export { Container };
