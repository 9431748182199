import React from 'react';
import { Col } from 'components/Core/Grid';
import { Container } from './styles';
import WebsiteImage from 'components/WebsiteImage';

const ToolboxSplashPostsNoResults = ({ clearFilters, noResultsImage }) => {
  return (
    <Container>
      <Col xs={24} sm={18} lg={12}>
        <WebsiteImage image={noResultsImage?.src} maxWidth="100%" />
        <p className="toolbox-detail-posts-no-results--text">
          Search not found, <span onClick={() => clearFilters()}>clear filters</span>.
        </p>
      </Col>
    </Container>
  );
};

export default ToolboxSplashPostsNoResults;
