import React, { useMemo } from 'react';
import ToolboxSplashFiltersMobile from './Mobile/ToolboxSplashFiltersMobile';
import ToolboxSplashFiltersDesktop from './Desktop/ToolboxSplashFiltersDesktop';

const ToolboxSplashFiltersContainer = (props) => {
  return (
    <>
      {!props.displayMobileFilters && <ToolboxSplashFiltersDesktop {...props} />}
      <ToolboxSplashFiltersMobile {...props} view={props.displayMobileFilters} />
    </>
  );
};

export default ToolboxSplashFiltersContainer;
