import React from 'react';
import { Col, Row } from 'components/Core/Grid';
import { STIcon } from 'components/Brand/Icons/STIcon';

import { Container } from './styles';

const ToolboxSplashFiltersList = ({ filters, handleListItemClick, isSelected }) => {
  return (
    <Container>
      <Row gutter={[0, 16]} type="flex" direction="column" className="row-container">
        {filters.map((filter, index) => {
          return (
            <Col span={24} key={index}>
              <p className="toolbox-details-filters-list--title">
                <b>{filter.title}</b>
              </p>
              {filter.list.map((listItem, index) => {
                return (
                  <p
                    key={index}
                    className={`toolbox-details-filters-list--list-item ${
                      isSelected(listItem) && 'selected'
                    }`}
                    onClick={() => handleListItemClick(listItem, filter.category)}
                  >
                    {listItem}{' '}
                    {isSelected(listItem) && (
                      <STIcon
                        fill="var(--titan-blue-3)"
                        type="close-circle-outline"
                        className="toolbox-details-filters-list--remove-filter-icon"
                      />
                    )}
                  </p>
                );
              })}
            </Col>
          );
        })}
      </Row>
    </Container>
  );
};

export default ToolboxSplashFiltersList;
